.ck-content {
  h1.cms-heading {
    font-weight: 500;
    font-size: 32px;
    line-height: 1.5;
    margin: 8px 0 20px 0;
  }

  h2.cms-heading {
    font-size: 28px;
    line-height: 1.5;
    font-weight: 400;
    margin: 8px 0;
  }

  h3.cms-heading {
    margin: 8px 0;
    font-weight: 500;
    line-height: 1.5;
    font-size: 24px;
  }

  h4.cms-heading {
    margin: 8px 0;
    font-weight: 400;
    line-height: 1.5;
    font-size: 20px;
  }

  h5.cms-heading {
    margin: 8px 0;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 1.5;
    font-size: 16px;
  }
}
