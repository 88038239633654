@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

html, body, .App, a, label, *, input, button {
  font-family: Roboto, sans-serif;
}

button:focus {
  outline: none;
}

a {
  color: #0e8fc9;
}

.App {
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  display: flex;
  overflow: hidden;

  .search-page { padding-bottom: 0; }
}

.cybercube-notifications {
  top: 82px;
}

.overlay-wrapper {
  text-align: center;
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.App.pdf {
  height: auto;
  width: auto;
  display: block;
  overflow: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.swagger-ui {
  .servers>label select {
    min-width: 130px;
    max-width: 400px;
  }

  .model-toggle {
    top: 0;
    margin: auto 0;
  }

  section.models {
    border: none;
  }

  .info {
    margin: 16px 20px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.simplebar-scrollbar::before {
  background-color: rgba(0, 0, 0, 0.5);
}

.simplebar-mask {
  z-index: auto;
}
