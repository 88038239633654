.group-navigation-container {
  display: flex;
  align-items: center;
  background-color: #fafafa;
  font-size: 14px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  padding: 12px 60px;
  z-index: 22;

  .group-navigation-links-wrapper {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;

    span {
      display: flex;
      align-items: center;
      min-width: 60px;
      font-size: 14px;
      color: #525252;
      margin-right: 12px;
    }

    .group-navigation-link {
      display: inline-flex;
      align-items: center;
      height: 24px;
      min-width: 60px;
      color: #0e8fc9;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        opacity: 0.7;
        transition: opacity 0.3s ease;
      }

      .separator {
        height: 16px;
        width: 1px;
        min-width: 1px;
        background-color: #d9d9d9;
        margin: 0 12px;
      }
    }
  }
}