.swagger-ui .markdown p, .swagger-ui .markdown pre, .swagger-ui .renderedMarkdown p, .swagger-ui .renderedMarkdown pre {
    font-size: 14px;
    line-height: 1.5;
    font-family: Roboto, sans-serif;
    color: #525252;
    text-align: justify;
}

.swagger-ui h2 span {
    display: inline-flex;
    align-items: center;
    margin-left: 8px;
    margin-top: 4px;
}
.swagger-ui .info li, .swagger-ui .info p, .swagger-ui .info table {
    font-size: 14px;
    font-family: Roboto, sans-serif;
    color: #525252;
    line-height: 1.5;
}
.swagger-ui .markdown code, .swagger-ui .renderedMarkdown code {
    background-color: hsla(0, 0%, 78%, 0.3);
    padding: .15em;
    border-radius: 2px;

    font-family: Source Code Pro, monospace;
    font-weight: 600;
    color: #9012fe;
    font-size: 12px;
    text-align: left;
}
